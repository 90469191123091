import React, { Suspense, lazy } from 'react';
import {
  DidomiNumberInputField,
  DidomiSelectField,
  DidomiSelectOption,
  DidomiSelectOptions,
  DidomiSwitchField,
  DidomiTextInputField,
} from '@didomi/ui-atoms-react';
import { useField } from 'formik';
import { FormField } from '@types';
import { getSafeNumber } from '@utils';
import { FormHelperTooltip } from 'components/FormHelperTooltip/FormHelperTooltip';

const LazyEditor = lazy(() =>
  import('../JsonEditorField/JsonEditorField').then(module => ({
    default: module.JsonEditorField,
  })),
);

interface ConfigurationFormFieldProps {
  field: FormField;
  disabled: boolean;
}

export const ConfigurationFormField = ({ field, disabled }: ConfigurationFormFieldProps) => {
  const [, , helpers] = useField(field.formControlName);

  switch (field.type) {
    case 'number':
      return (
        <DidomiNumberInputField
          name={field.formControlName}
          label={field.id}
          placeholder={field.placeholder}
          required={field.required}
          disabled={disabled}
          onValueChange={e => helpers.setValue(getSafeNumber(e.detail))}
        >
          <FormHelperTooltip content={field.description} />
        </DidomiNumberInputField>
      );
    case 'boolean':
      return (
        <DidomiSwitchField
          name={field.formControlName}
          required={field.required}
          valueSelected={true}
          valueNotSelected={false}
          accent
          disabled={disabled}
        >
          <div className="flex items-center">
            {field.id}
            <FormHelperTooltip content={field.title || field?.description} slot={null} />
          </div>
        </DidomiSwitchField>
      );
    case 'array':
      return (
        <DidomiSelectField
          name={field.formControlName}
          placeholder={field.placeholder}
          label={field.id}
          multi={field.maxItems !== 1}
          required={field.required}
          disabled={disabled}
          onValueChange={e =>
            // Value should be an array regardless whether select is multi or not
            Array.isArray(e.detail) ? helpers.setValue(e.detail) : helpers.setValue([e.detail])
          }
        >
          <FormHelperTooltip content={field.description} />
          <DidomiSelectOptions>
            {field.options?.map(option => (
              <DidomiSelectOption
                key={option.value}
                value={option.value}
                label={option.label}
                disabled={disabled}
              />
            ))}
          </DidomiSelectOptions>
        </DidomiSelectField>
      );
    case 'object':
      return (
        <Suspense fallback={<div>Loading...</div>}>
          <LazyEditor name={field.formControlName} placeholder={field.placeholder} label={field.id}>
            <FormHelperTooltip content={field.description} />
          </LazyEditor>
        </Suspense>
      );
    default:
      return (
        <DidomiTextInputField
          name={field.formControlName}
          label={field.id}
          placeholder={field.placeholder}
          required={field.required}
          disabled={disabled}
        >
          <FormHelperTooltip content={field.description} />
        </DidomiTextInputField>
      );
  }
};
