import React from 'react';
import { getTranslatedValue } from '@didomi/helpers';
import { MappingPurposes } from '@didomi/integrations-sdk';
import { DidomiTreeCard } from '@didomi/ui-atoms-react';
import { MappingOption } from '@types';
import { MappingField } from 'components/MappingField/MappingField';
import { MappingPreferencesTree } from 'components/MappingPreferencesTree/MappingPreferencesTree';

interface MappingPurposeCardProps {
  purposeWrapper: MappingPurposes;
  mappingOptions: MappingOption[];
}

export const MappingPurposeCard = ({ purposeWrapper, mappingOptions }: MappingPurposeCardProps) => {
  const purpose = purposeWrapper as any;
  const isCollapsible = !(
    !purpose?.selected_preferences || purpose?.selected_preferences?.length === 0
  );
  return (
    <DidomiTreeCard
      isExpanded={false}
      headerText={getTranslatedValue(purpose?.description)}
      headerIcon="purpose"
      isCollapsible={isCollapsible}
    >
      {/* Purpose Controls */}
      <div slot="header-controls">
        <MappingField name={purposeWrapper.id} mappingOptions={mappingOptions} />
      </div>

      {/* Preferences tree of the purpose */}
      {purpose?.selected_preferences?.length > 0 && (
        <MappingPreferencesTree
          preferences={purpose?.selected_preferences}
          mappingOptions={mappingOptions}
        />
      )}
    </DidomiTreeCard>
  );
};
