import React from 'react';
import { DidomiIconButton } from '@didomi/ui-atoms-react';
import { useWebhookSample } from '@hooks';

export const WebhookSample = () => {
  const { mutate: downloadWebhookSample } = useWebhookSample();

  const webhookData = [
    { name: 'Event created', type: 'event.created' },
    { name: 'Event updated', type: 'event.updated' },
    { name: 'Event deleted', type: 'event.deleted' },
    { name: 'User created', type: 'user.created' },
    { name: 'User updated', type: 'user.updated' },
    { name: 'User deleted', type: 'user.deleted' },
  ];

  return (
    <div className="bg-white p-6 rounded-lg shadow-md max-w-md">
      <h3 className="text-sm font-semibold">Sample files for webhook data</h3>
      <p className="text-sm text-gray-600 mb-4">
        You can download sample JSON files for events sent by the webhook:
      </p>
      <ul className="space-y-2">
        {webhookData.map(item => (
          <li key={item.type} className="flex items-center justify-between">
            <button
              type="button"
              className="text-blue-600 hover:underline"
              onClick={() =>
                downloadWebhookSample({
                  download: true,
                  eventType: item.type as any,
                })
              }
            >
              {item.name} ({item.type})
            </button>
            <DidomiIconButton
              icon="download"
              variant="rounded-no-border"
              size="small"
              onClick={() =>
                downloadWebhookSample({
                  download: true,
                  eventType: item.type as any,
                })
              }
            />
          </li>
        ))}
      </ul>
    </div>
  );
};
