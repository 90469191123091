import { useMemo } from 'react';
import { getMappingFormInitialValues, getMappingFormValidationSchema } from '@utils';
import { useMappingPurposes } from './useMappingPurposes.hook';
import { useSubscriptionMappingOptions } from './useSubscriptionMappingOptions.hook';
import { useSubscriptionSetup } from './useSubscriptionSetup.hook';

// Helpers hook that provides all the necessary data for the Subscription Mapping form
export const useSubscriptionMappingState = (integrationId: string) => {
  const { subscriptionConfig } = useSubscriptionSetup(integrationId);
  const { data: mappingOptions = [], isLoading: isMappingOptionsLoading } =
    useSubscriptionMappingOptions(subscriptionConfig?.id);
  const { data: mappingPurposes, isLoading: isMappingPurposesLoading } = useMappingPurposes();

  const initialValues = useMemo(() => {
    return getMappingFormInitialValues(mappingPurposes, subscriptionConfig?.mappings);
  }, [mappingPurposes, subscriptionConfig]);

  const validationSchema = useMemo(
    () => getMappingFormValidationSchema(initialValues),
    [initialValues],
  );

  const isLoading = isMappingOptionsLoading || isMappingPurposesLoading;

  return {
    mappingPurposes,
    initialValues,
    validationSchema,
    mappingOptions,
    isLoading,
  };
};
