export const QUERY_KEYS = {
  SUBSCRIPTION_CONFIG_TYPES: (typeIds: string[]) => ['subscription-config-types', typeIds],
  MAPPING_OPTIONS: (configId: string) => ['mapping-options', configId],
  SUBSCRIPTION_CONFIGS: (typeIds: string[]) => ['subscription-configs', typeIds],
  PREMIUM_FEATURES: (orgId: string) => ['premium-features', orgId],
  CONFIG_TREE: (orgId: string) => ['config-tree', orgId],
  ORGANIZATION_PURPOSES: (orgId: string) => ['organization-purposes', orgId],
  GLOBAL_PURPOSES: ['global-purposes'],
  MAPPING_PURPOSES: (orgId: string) => ['mapping-purposes', orgId],
  INTEGRATIONS: ['integrations'],
};
