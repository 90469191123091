import { PaginatedResponse } from '@didomi/helpers';
import { MappingPurposes } from '@didomi/integrations-sdk';
import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { QUERY_KEYS } from '@constants';
import { useAxiosWithLogOut } from './useAxiosWithInterceptors.hook';

/**
 * Fetch configuration tree for current organization
 * First returned configuration is used
 * */
export const useMappingPurposes = () => {
  const { organizationId } = useActiveOrganization();
  const axiosWithInterceptors = useAxiosWithLogOut();

  const fetchMappingPurposes = async () => {
    const response = await axiosWithInterceptors.get<PaginatedResponse<MappingPurposes>>(
      `${CONFIG.environmentConfig.apiBaseUrl}marketplace/mapping-purposes?organization_id=${organizationId}`,
    );
    return response.data.data;
  };

  return useQuery<MappingPurposes[], AxiosError>(
    QUERY_KEYS.MAPPING_PURPOSES(organizationId),
    fetchMappingPurposes,
  );
};
