import { reduceToRecord } from '@didomi/helpers';
import { MappingPurposes } from '@didomi/integrations-sdk';
import { ConfigTreePreferenceWrapper, SubscriptionConfigMapping } from '@types';

// Use null as a default value - as it will be checked to determine if form is dirty
export const MAPPING_EMPTY_VALUE = null;

export const getMappingFormInitialValues = (
  selectedPurposes: MappingPurposes[],
  mappings: SubscriptionConfigMapping[] = [],
): Record<string, string> => {
  const initialValues = {};
  // Create a record of mappings for quick lookup
  const mappingsRecord = reduceToRecord('value', 'id', mappings);

  const addToInitialValues = (id: string) => {
    initialValues[id] = mappingsRecord[id] || MAPPING_EMPTY_VALUE;
  };

  // Recursively iterate over preferences tree and fill the initial values
  const addPreferencesToValues = (preferenceWrappers: ConfigTreePreferenceWrapper[]) => {
    preferenceWrappers.forEach(preferenceWrapper => {
      addToInitialValues(preferenceWrapper.id);

      preferenceWrapper.preference.values.forEach(value => {
        addToInitialValues(preferenceWrapper.id + '_' + value.id);

        // Recursively call the function to iterate over preferences
        addPreferencesToValues(value.selected_preferences);
      });
    });
  };

  selectedPurposes?.forEach(p => {
    addToInitialValues(p.id);
    const value = p as any;
    if (value?.selected_preferences) {
      addPreferencesToValues(value.selected_preferences || []);
    }
  });

  return initialValues;
};
