import React, { useMemo } from 'react';
import { reduceToRecord } from '@didomi/helpers';
import { Form, Formik } from 'formik';
import { FormField } from '@types';
import { getDynamicValidator } from '@utils';
import { ConfigurationFormField } from '../ConfigurationFormField/ConfigurationFormField';

interface ConfigurationFormProps {
  fields: FormField[];
  disabled?: boolean;
  children: (props: {
    isValid: boolean;
    dirty: boolean;
    values: Record<string, unknown>;
  }) => React.ReactNode;
  typeSchemaSelect?: React.ReactChild;
  additionalFieldInformation?: Record<string, React.ReactElement>;
  onSubmit?: (values: Record<string, unknown>) => void;
}

export const ConfigurationForm = ({
  fields,
  disabled,
  children,
  typeSchemaSelect,
  onSubmit,
  additionalFieldInformation: additionalField,
}: ConfigurationFormProps) => {
  const initialValues = useMemo(() => reduceToRecord('formControlName', 'value', fields), [fields]);
  const validationSchema = useMemo(() => getDynamicValidator(fields), [fields]);

  return (
    <section>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, isValid, dirty }) => (
          <Form noValidate>
            <fieldset className="max-w-[512px] mx-auto px-14 py-12 rounded-lg shadow-setup-form-card bg-setup-form-card">
              {/* Type schema select is controlled and passed from parent component - based on it rest of the fields are generated */}
              {typeSchemaSelect}

              {fields
                // Show field only if field that it depends on is truthy(dependsOn is true)
                .filter(f => (f.dependsOn ? values[f.dependsOn] : true))
                .map(field => (
                  <>
                    <ConfigurationFormField
                      key={field.formControlName}
                      field={field}
                      disabled={disabled}
                    />
                    {values[field.formControlName] && additionalField?.[field.formControlName]}
                  </>
                ))}
            </fieldset>

            {/* Dynamic form footer that have access to values and validity */}
            {children({ isValid, dirty, values })}
          </Form>
        )}
      </Formik>
    </section>
  );
};
