import { DidomiConsentChangeEventType } from '@didomi/integrations-sdk';
import { useActiveOrganization } from '@didomi/utility-react';
import { useMutation } from 'react-query';
import { useAxiosWithLogOut } from './useAxiosWithInterceptors.hook';

/**
 * Fetch configuration tree for current organization
 * First returned configuration is used
 * */
export const useWebhookSample = () => {
  const { organizationId } = useActiveOrganization();
  const axiosWithInterceptors = useAxiosWithLogOut();

  const fetchWebhookSample = async (props: {
    eventType: DidomiConsentChangeEventType;
    download: boolean;
  }) => {
    const response = await axiosWithInterceptors.get(
      `${CONFIG.environmentConfig.apiBaseUrl}marketplace/integrations/webhook/samples?organization_id=${organizationId}&event_type=${props.eventType}&download=true`,
      props.download ? { responseType: 'blob' } : {},
    );

    if (props.download) {
      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      const fileName = `${organizationId}-${props.eventType.replace('.', '-')}-input-schema.json`;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }
    return response.data.data;
  };

  return useMutation(fetchWebhookSample);
};
